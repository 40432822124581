import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import './main.scss'

// Icons
import {FaLinkedin, FaGithub} from 'react-icons/fa'

// Assets
import profilePic from '../assets/me.jpg'

const width = window.innerWidth

const About = ({darkMode}) => {
  return (
    <>
      <Container style={{fontFamily: 'Space Grotesk'}}>

        {/* wide display */}
        <div className='d-none d-lg-block'>
          <Row className='mt-5 mb-5'>
            <h1 className='text-center'>About Me</h1>
          </Row>
          <Row className='justify-content-center'>
            <Col className={width < 1200 ? 'col-md-4' : 'col-md-3'}>
              <img className={width < 1067 ? 'mt-4' : 'mt-2'} src={profilePic} style={{borderRadius: '50%'}}/>
            </Col>
            <Col className='col-md-5' style={width > 1457 ? {textAlign: 'justify', textJustify: 'inter-word'} : undefined}>
              <p className='mt-4'>My name is Moe Yagmour, and I am an experienced IT professional seeking to continue my learning journey and expand my skillset.</p>
              <p>I am a highly organized and proactive individual who thrives in a fast-paced and dynamic environment. My passion for planning and execution has enabled me to consistently deliver results and exceed expectations.</p>
              <p>I am eager to take on new challenges and further develop my abilities in the field of Informatics.</p>
              <hr style={{margin: 'auto', width: '50%'}}/>
              <Row className="justify-content-center align-items-center mt-4">
                <Col className='col-md-3 text-center'>
                  <a className={darkMode ? 'button-dark' : 'button-light'} href="https://www.linkedin.com/in/yagmour/"><FaLinkedin size={28}/></a>
                </Col>
                <Col className='col-md-3 text-center'>
                  <a className={darkMode ? 'button-dark' : 'button-light'} href="https://github.com/m-yag"><FaGithub size={28}/></a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className='d-block d-lg-none'>
          <Row className='mt-5 mb-5'>
              <h1 className="text-center">About Me</h1>
          </Row>
          <Row style={{margin: 'auto'}}>
            <Col className='col-md-5 text-center' style={{margin: 'auto'}}>
              <img src={profilePic} style={{borderRadius: '50%'}}/>
            </Col>
          </Row>
          <Row>
            <hr className='mt-4' style={{margin: 'auto', width: '50%'}}/>
            <p className='mt-4'>My name is Moe Yagmour, and I am an experienced IT professional seeking to continue my learning journey and expand my skillset.</p>
            <p>I am a highly organized and proactive individual who thrives in a fast-paced and dynamic environment. My passion for planning and execution has enabled me to consistently deliver results and exceed expectations.</p>
            <p>I am eager to take on new challenges and further develop my abilities in the field of Informatics.</p>
            <hr style={{margin: 'auto', width: '50%'}}/>
          </Row>
          <Row className='mt-4 justify-content-center'>
            <Col className='col-3 text-center'>
              <a className={darkMode ? 'button-dark' : 'button-light'} href="https://www.linkedin.com/in/yagmour/"><FaLinkedin size={28}/></a>
            </Col>
            <Col className='col-3 text-center'>
              <a className={darkMode ? 'button-dark' : 'button-light'} href="https://github.com/m-yag"><FaGithub size={28}/></a>
            </Col>
          </Row>
        </div>

        <br/>
          <hr className='mt-3'/>

        <Row className='text-center mb-2'>
          <h2 className='mt-3'>Portfolio of Expertise</h2>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <p className='mt-2'>I have a broad and solid understanding of the following languages and technologies:</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={3}>
            <p><strong>Web Languages & Frameworks</strong></p>
            <ul style={{listStyleType: 'square'}}>
              <li>JavaScript</li>
              <li>Node.js</li>
              <li>React.js</li>
              <li>Bootstrap</li>
              <li>CSS/Sass</li>
            </ul>
          </Col>
          <Col md={3}>
            <p><strong>Programming Languages</strong></p>
            <ul style={{listStyleType: 'square'}}>
              <li>Python</li>
              <li>Java</li>
              <li>C/C++</li>
            </ul>
          </Col>
          <Col md={3}>
            <p><strong>Systems & CLI Tools</strong></p>
            <ul style={{listStyleType: 'square'}}>
              <li>Linux</li>
              <li>Bash</li>
              <li>Git</li>
            </ul>
          </Col>
        </Row>

        <br/>
        <hr className='mt-3'/>

        <Row className='text-center mb-3'>
          <h2 className='mt-3'>Professional Experience</h2>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <strong>JAZ Aviation <span style={{float: 'right'}}>2019 - 2021</span></strong>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <span><i>System Analyst</i></span>
            <ul style={{listStyleType: 'square'}}>
              <li>Maintained local/cloud system infrastructure.</li>
              <li>Implemented scripts (Bash, Powershell, Python) to automate routine tasks across multiple systems.</li>
              <li>Worked with engineering teams to provide software support in a number of projects including:</li>
              <ul>
                <small><li>Aircraft fueling supply operations.</li></small>
                <small><li>Flight status data feeds and aviation analytics.</li></small>
              </ul>
              <li>Produced technical documentation on infrastructure, policies, and procedures.</li>
              <li>Assisted with front-end development and maintenance.</li>
            </ul>
          </Col>
        </Row>

        <br/>
        <hr className='mt-3'/>

        <Row className='text-center mb-3'>
          <h2 className='mt-3'>Education</h2>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <strong>University of Windsor <span style={{float: 'right'}}>2022 - Present</span></strong>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <span><i>B.Sc.CS - Computer Science (Honours)</i></span>
            <ul style={{listStyleType: 'square'}}>
              <li>Taking additional courses in pursuit of a M.Sc.</li>
            </ul>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <strong>University of Windsor <span style={{float: 'right'}}>2014 - 2019</span></strong>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <span><i>B.Sc.CS - Computer Science</i></span>
            <ul style={{listStyleType: 'square'}}>
              <li>Minor in Modern Languages - German.</li>
            </ul>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <strong>ERAU / Flight Academy <span style={{float: 'right'}}>2010 - 2013</span></strong>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <ul style={{listStyleType: 'square'}}>
              <li>Completion of Pilot License (PPL/IR)</li>
              <li>Attended Embry-Riddle's Aeronautics Associate Program.</li>
            </ul>
          </Col>
        </Row>

      </Container>
    </>
  )
}

export default About
