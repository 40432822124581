import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import './main.scss'

const Chessmite = ({darkMode}) => {
  return (
    <>
      <Container style={{fontFamily: 'Space Grotesk'}}>
        <Row className='mt-5 text-center'>
          <h1>Chessmite</h1>
        </Row>
        <Row className='mt-5 justify-content-center'>
          <Col md={9}>
            <p>This page is under construction. For more details:</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={9}>
            <ul style={{listStyleType: 'square'}}>
              <li><a className={darkMode ? 'link_dark' : 'link'} href='https://github.com/m-yag/chessmite'>github.com/m-yag/chessmite</a></li>
              <li><a className={darkMode ? 'link_dark' : 'link'} href='https://www.npmjs.com/package/chessmite'>npmjs.com/package/chessmite</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Chessmite
