import {BrowserRouter as Router} from 'react-router-dom'
import {ProSidebarProvider} from 'react-pro-sidebar'

import Main from './components/Main'

const App = () => {
   return (
    <ProSidebarProvider>
      <Router>
        <Main />
      </Router>
    </ProSidebarProvider>
  )
}

export default App
