import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Sidebar, sidebarClasses, Menu, MenuItem, SubMenu} from 'react-pro-sidebar'
import Switch from 'react-switch'
import CollapseButton from './CollapseButton'
import {FaHome, FaCode, FaUser, FaMoon, FaChevronRight, FaChevronDown} from 'react-icons/fa'
import './hover.scss'

const Navbar = ({setLoadNum, collapsed, broken, toggleSidebar, darkMode, setDarkMode}) => {

//  const {collapsed, toggleSidebar, broken} = useProSidebar()

  const location = useLocation()
  const path = location.pathname

  if(path != '/') setLoadNum(2)

  console.log(path)

  return (

    <div style={path == '/' ? {display: "flex", position: "relative", height: '100vh'}
    : {display: 'flex', height: '100vh', position: 'sticky', top: 0}}>

      <Sidebar customBreakPoint="530px" width="180px" collapsedWidth="3.5em" style={{height: "", marginRight: "0px", borderRight: "0px"}}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: 'rgba(25, 25, 25, 1)'
          }
        }}
      >

        <div style={{textAlign: "center", marginTop: "1em", marginBottom: "5vh", fontSize: "1.1em", fontWeight: "900", fontFamily: 'Space Grotesk', color: "#fff"}}>
          {collapsed ? <div><span>PORT</span><br/><span>FOLIO</span></div> : "PORTFOLIO"}
          <div style={{marginLeft: "0.4em", marginRight: "0.4em"}}>
            <hr style={{border: "1px solid #b35900"}}/>
          </div>
        </div>

        <Menu style={{fontWeight: 900, fontFamily: 'Space Grotesk'}}
          renderExpandIcon={({open}) => <span>{collapsed ? '' : open ? <FaChevronDown size={12}/> : <FaChevronRight size={12}/> }</span>}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0) {
                return {
                  color: disabled ? '#fff' : '#fff',
                  backgroundColor: active ? '#b35900' : '#191919',
                  '&:hover': active ? {color: '#fff', background: '#b35900'} : {
                    color: '#fff',
                    background:
                      `linear-gradient(to right, #b35900 4px, transparent 4px) 5% 5%,
                      linear-gradient(to right, #b35900 4px, transparent 4px) 5% 95%,
                      linear-gradient(to left, #b35900 4px, transparent 4px) 95% 5%,
                      linear-gradient(to left, #b35900 4px, transparent 4px) 95% 95%,
                      linear-gradient(to bottom, #b35900 4px, transparent 4px) 5% 5%,
                      linear-gradient(to bottom, #b35900 4px, transparent 4px) 95% 5%,
                      linear-gradient(to top, #b35900 4px, transparent 4px) 5% 95%,
                      linear-gradient(to top, #b35900 4px, transparent 4px) 95% 95%`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "15px 15px"
                  }
                }
              }
              else {
                return {
                  color: disabled ? '#fff' : '#fff',
                  backgroundColor: active ? '#b35900' : '#292929',
                  '&:hover': active ? {color: '#fff', background: '#b35900'} : {
                    color: '#fff',
                    background:
                      `linear-gradient(to right, #b35900 4px, transparent 4px) 5% 5%,
                      linear-gradient(to right, #b35900 4px, transparent 4px) 5% 95%,
                      linear-gradient(to left, #b35900 4px, transparent 4px) 95% 5%,
                      linear-gradient(to left, #b35900 4px, transparent 4px) 95% 95%,
                      linear-gradient(to bottom, #b35900 4px, transparent 4px) 5% 5%,
                      linear-gradient(to bottom, #b35900 4px, transparent 4px) 95% 5%,
                      linear-gradient(to top, #b35900 4px, transparent 4px) 5% 95%,
                      linear-gradient(to top, #b35900 4px, transparent 4px) 95% 95%`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "15px 15px",
                    backgroundColor: "#292929"
                  }
                }
              }
            },
          }}
        >
          { path === '/' ?
          <MenuItem active onClick={()=>toggleSidebar()} icon={ collapsed ? <FaHome style={{marginRight: "19px"}}/> : <FaHome/>}  routerLink={<Link to="/" />}>HOME</MenuItem>
          : <MenuItem onClick={()=>toggleSidebar()} icon={collapsed ? <FaHome style={{marginRight: "19px"}}/>: <FaHome />} routerLink={<Link to="/" />}>HOME</MenuItem>
          }

          <SubMenu icon={ collapsed ? <FaCode style={{marginRight: "19px"}}/> : <FaCode />} label="PROJECTS">
            { path === '/chessmite' ?
            <MenuItem active onClick={()=>toggleSidebar()} routerLink={<Link to="/chessmite" />}>CHESSMITE</MenuItem>
            : <MenuItem onClick={()=>toggleSidebar()} routerLink={<Link to="/chessmite" />}>CHESSMITE</MenuItem>
            }
          </SubMenu>

          { path === '/about' ?
          <MenuItem active onClick={()=>toggleSidebar()} icon={ collapsed ? <FaUser style={{marginRight: "19px"}} /> : <FaUser />} routerLink={<Link to="/about" />}>ABOUT</MenuItem>
          : <MenuItem onClick={()=>toggleSidebar()} icon={ collapsed ? <FaUser style={{marginRight: "19px"}}/> : <FaUser />} routerLink={<Link to="/about" />}>ABOUT</MenuItem>
          }

          <br/>

          <div style={
            broken ?
            {
              position: "absolute",
              marginBottom: "7em",
              bottom: 0,
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center"
            } :
            collapsed ?
            {
              position: "absolute",
              marginBottom: "2em",
              bottom: 0,
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
            } : {
              position: "absolute",
              marginBottom: "2em",
              bottom: 0,
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center"
            }
          }>

            <div style={collapsed ? {display: "flex", justifyContent: "center", marginLeft: "0.4em", marginBottom: "0.3em"} :
              {}
            }>
              <FaMoon style={ darkMode ? {color: "#b35900", marginRight: "0.6em", marginBottom: "0.5em"} : {color: "#fff", marginRight: "0.6em", marginBottom: "0.5em"}}/>
            </div>

            <div style={collapsed ? {display: "flex", justifyContent: "center"} : {}}>
              <Switch
                onChange={() => setDarkMode(!darkMode)}
                checked={darkMode}
                onColor="#b35900"
                checkedIcon={false}
                uncheckedIcon={false}
                height={17}
                width={38}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                borderRadius={4}

              />
            </div>

          </div>

        </Menu>

        <div style={{position: "absolute", top:"50%", right: "3%", zIndex: 9}}>
          {broken ? '' : <CollapseButton className="hov" collapsed={collapsed} />}
        </div>

      </Sidebar>

    </div>
  )
}

export default Navbar
