import React, {useState, useEffect} from 'react'
import {Routes, Route} from 'react-router-dom'
import WebFont from 'webfontloader'
import {useProSidebar} from 'react-pro-sidebar'

import Home from './Home'
import About from './About'
import Chessmite from './Chessmite'
import Navbar from './navbar/Navbar'
import './main.scss'

import {FiMenu} from 'react-icons/fi'

const Main = () => {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Orbitron', 'Space Grotesk', 'Courier Prime']
      }
    })
  }, [])

  const [loadNum, setLoadNum] = useState(0)
  const [darkMode, setDarkMode] = useState(false)
  const {collapsed, broken, toggleSidebar} = useProSidebar()

  console.log(darkMode)
  console.log(broken ? 'broken' : 'not broken')

  return (
    <>
      <div className={darkMode ? "wrapper dark2" : "wrapper"}>
        {broken ?
        <div style={{position: "absolute", width: "100%", background: "#000", zIndex: 9}}>
          <div style={{display: "flex"}}>
            <button style={{position: "relative", color: "#b35900", background: "#000", border: "none"}} onClick={() => toggleSidebar()}><FiMenu size={32}/></button>
            <span style={{color: "#fff", position:"absolute", marginTop: "6px", right: "10px"}}>PORTFOLIO</span>
          </div>
        </div>
        : ''}
        <Navbar setLoadNum={setLoadNum} collapsed={collapsed} broken={broken} darkMode={darkMode} setDarkMode={setDarkMode} toggleSidebar={toggleSidebar}/>
        <Routes>
          <Route path="/" element={<Home darkMode={darkMode} loadNum={loadNum} setLoadNum={setLoadNum} />} />
          <Route path="about" element={<About darkMode={darkMode}/>} />
          <Route path="chessmite" element={<Chessmite darkMode={darkMode}/>} />
        </Routes>
      </div>
    </>
  )
}

export default Main
