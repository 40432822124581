import React from 'react'
import {useProSidebar} from 'react-pro-sidebar'
import {BiChevronsLeft, BiChevronsRight} from 'react-icons/bi'
import './hover.scss'

const Navbar = ({collapsed}) => {

  const {collapseSidebar} = useProSidebar();

    return (
      <button className="hov" style={{color: "#fff", background: "none", border: "none"}} onClick={() => collapseSidebar()}>
        { collapsed ? <BiChevronsRight className="hov" size={25} /> : <BiChevronsLeft className="hov" size={25} /> }
      </button>
    )
}

export default Navbar
