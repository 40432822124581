import React, {useState, useCallback} from 'react'
import BsContainer from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import 'bootstrap/dist/css/bootstrap.min.css'
import './main.scss'

// particle modules
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

// animation
import {Animate} from 'react-simple-animate'
import {TypeAnimation} from 'react-type-animation'

// particle conf files
import lightParticleSm from './particle/lightParticleSm'
import lightParticleSmTall from './particle/lightParticleSmTall'
import lightParticle from './particle/lightParticle'
import lightParticleMd from './particle/lightParticleMd'
import darkParticleSm from './particle/darkParticleSm'
import darkParticleSmTall from './particle/darkParticleSmTall'
import darkParticle from './particle/darkParticle'
import darkParticleMd from './particle/darkParticleMd'

const Home = ({darkMode, loadNum, setLoadNum}) => {

  const width = window.innerWidth
  const height = window.innerHeight

  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine);

    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    await console.log(container);
  }, []);

  if(loadNum == 2) {
    return (
      <>
        <BsContainer style={{width: 'fit-content', height: '100%', marginTop: "5em"}}>

          <div style={ darkMode ?
            {position: 'relative', height: '100%', zIndex: 1, backgroundColor: 'rgba(25,25,25,0.5)'}
            : {position: 'relative', height: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.6'}
          }
          >
            <div style={{outline: 'solid', outlineColor: '#b35900', outlineStyle: 'dashed', outlineWidth: '0.9px'}}>
              <div style={darkMode ?
                {color: '#fff', width: '29vh', paddingLeft: '5px', paddingRight: '5px'}
                : {width: '29vh', paddingLeft: '5px', paddingRight: '5px'}}
              >
                <h1 style={{fontFamily: 'Space Grotesk'}}>Moe Yagmour</h1>
                <ul style={{fontFamily: 'Space Grotestk', listStyleType: 'square'}}>
                  <li>System Analyst</li>
                  <li>Web Developer</li>
                  <li>Programmer</li>
                </ul>
                <hr/>
                <p style={{fontFamily: 'Space Grotesk'}}>Welcome to my web portfolio. Have a look around!</p>
              </div>
            </div>
          </div>

        </BsContainer>

        <Particles
          className=''
          style={{position: 'fixed', zIndex: -1}}
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={
            darkMode ?
              width < 500 ? height > 700 ? darkParticleSmTall : darkParticleSm : width < 900 ? darkParticleMd : darkParticle
            : width < 500 ? height > 700 ? lightParticleSmTall : lightParticleSm : width < 900 ? lightParticleMd : lightParticle
          }
        />
      </>
    )
  }

  if(loadNum == 1) {
    return (
      <>
        <BsContainer style={{width: 'fit-content', height: '100%', marginTop: "5em"}}>

          <div style={ darkMode ?
            {position: 'relative', height: '100%', zIndex: 1, backgroundColor: 'rgba(25,25,25,0.5)'}
            : {position: 'relative', height: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.6'}
          }
          >
            <div style={{outline: 'solid', outlineColor: '#b35900', outlineStyle: 'dashed', outlineWidth: '0.9px'}}>
              <div style={darkMode ?
                {color: '#fff', width: '29vh', paddingLeft: '5px', paddingRight: '5px'}
                : {width: '29vh', paddingLeft: '5px', paddingRight: '5px'}}
              >
                <h1 style={{fontFamily: 'Space Grotesk'}}>Moe Yagmour</h1>
                <ul style={{fontFamily: 'Space Grotesk', listStyleType: 'square'}}>
                  <li>System Analyst</li>
                  <li>Web Developer</li>
                  <li>Programmer</li>
                </ul>
                <hr/>
                <p style={{fontFamily: 'Space Grotesk'}}>Welcome to my web portfolio. Have a look around!</p>
              </div>
            </div>
          </div>

        </BsContainer>

        <Animate play={true} duration={5} start={{}} end={{}}>
          <Particles
            className='test'
            style={{position: 'fixed', zIndex: -1}}
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={
              darkMode ?
                width < 500 ? height > 700 ? darkParticleSmTall : darkParticleSm : width < 900 ? darkParticleMd : darkParticle
              : width < 500 ? height > 700 ? lightParticleSmTall : lightParticleSm : width < 900 ? lightParticleMd : lightParticle
            }
          />
        </Animate>
      </>
    )
  }

  if (loadNum == 0) {
    return (
      <>
        <BsContainer style={{width: 'fit-content', height: '100%', marginTop: "5em", marginLeft: 'auto', marginRight: 'auto'}}>

          <div style={ darkMode ?
            {position: 'relative', height: '100%', zIndex: 1, backgroundColor: 'rgba(25,25,25,0.5)'}
            : {position: 'relative', height: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.6'}
          }
          >
            <Animate play={true} delay={5.3}
              start={
              {outline: 'solid', outlineColor: 'rgba(179,89,0,0)', outlineStyle: 'dashed', outlineWidth: '1.1px'}
              }
              end = {
              {outline: 'solid', outlineColor: '#b35900', outlineStyle: 'dashed', outlineWidth: '1.1px'}
              }
              onComplete={() => setLoadNum(1)}
            >
              <div style={darkMode ? {color: '#fff', width: '29vh', paddingLeft: '5px', paddingRight: '5px'} : {width: '29vh', paddingLeft: '5px', paddingRight: '5px'}}>
              <TypeAnimation
                sequence={[100, 'Moe Yagmour']}
                speed={80}
                cursor= {false}
                wrapper='h1'
                style={{fontFamily: 'Space Grotesk'}}
              />
              <ul>
                <Animate play={true} delay={0.5} start={{visibility: 'hidden'}} end={{visibility: 'visible'}}>
                  <TypeAnimation
                    sequence={[1000, 'System Analyst']}
                    speed={80}
                    cursor= {false}
                    wrapper='li'
                    style={{fontFamily: 'Space Grotesk', listStyleType: 'square'}}
                  />
                </Animate>
                <Animate play={true} delay={1.5} start={{visibility: 'hidden'}} end={{visibility: 'visible'}}>
                  <TypeAnimation
                    sequence={[2000, 'Web Developer']}
                    speed={80}
                    cursor= {false}
                    wrapper='li'
                    style={{fontFamily: 'Space Grotesk', listStyleType: 'square'}}
                  />
                </Animate>
                <Animate play={true} delay={2.5} start={{visibility: 'hidden'}} end={{visibility: 'visible'}}>
                  <TypeAnimation
                    sequence={[3000, 'Programmer']}
                    speed={80}
                    cursor= {false}
                    wrapper='li'
                    style={{fontFamily: 'Space Grotesk', listStyleType: 'square'}}
                  />
                </Animate>
              </ul>

              <Animate play={true} duration={0.5} delay={3.3} start={{opacity: 0}} end={{opacity: 1}}>
                <hr/>
              </Animate>

              <TypeAnimation
                sequence={[4300, 'Welcome to my web portfolio. Have a look around!']}
                speed={80}
                cursor= {false}
                wrapper='p'
                style={{fontFamily: 'Space Grotesk'}}
              />

              </div>
            </Animate>
          </div>

        </BsContainer>

        <BsContainer className="d-none">
          <div style={{position: 'relative', height: '100%', zIndex: 1}}>
            <div style={darkMode ? {backgroundColor: 'rgba(25,25,25,0.5)', color: '#fff', width: '10em'} : {backgroundColor: 'rgba(255,255,255,0.5)', width: '10em'}}>
              <TypeAnimation
                sequence={[100, 'Moe Yagmour']}
                speed={75}
                cursor= {false}
                wrapper='h1'
                style={{fontFamily: 'Space Grotesk'}}
              />
              <TypeAnimation
                sequence={[1000, 'Stuff about me.']}
                speed={75}
                cursor= {false}
                wrapper='p'
                style={{fontFamily: 'Space Grotesk'}}
              />
            </div>
          </div>
        </BsContainer>

      </>
    )
  }
}

export default Home
